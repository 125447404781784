<template>
    <v-container class="pa-0">
        <v-row align="center" :class="{ 'grey--text': disabled }">
            <v-col cols="auto" class="py-0">
                <v-switch
                    v-model="isLinked"
                    label="Vimeo"
                    :loading="loading"
                    :disabled="loading || disabled"
                    hide-details
                    class="mt-0 py-3"
                    @change="onToggle"
                />
            </v-col>
            <v-col v-if="connection?.display_name" class="py-0 overflow-hidden">
                <v-chip small color="secondary" text-color="white">
                    <v-icon left x-small>user</v-icon>
                    {{ connection?.display_name }}
                </v-chip>
            </v-col>
            <!-- in a dialog -->
            <connect-vimeo :company-id="companyId" @cancelled="onCancel" />
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import ConnectVimeo from './ConnectVimeo.vue';

import { CompanyAccountConnection } from '@/types/Company';

const VimeoConnectorProps = Vue.extend({
    name: 'VimeoConnector',
    props: {
        companyId: {
            type: Number,
            default() {
                return 0;
            }
        },
        connection: {
            type: Object as PropType<CompanyAccountConnection | null>,
            default(): CompanyAccountConnection | null {
                return null;
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        ConnectVimeo
    }
})
export default class VimeoConnector extends VimeoConnectorProps {
    isLinked = Boolean(this.connection);

    connect() {
        this.$store.dispatch('modal/open', 'connect-vimeo');
    }

    onToggle(isLinked: boolean) {
        if (isLinked) {
            if (!this.connection) {
                this.connect();
            }
        } else {
            if (this.connection) {
                this.$emit(
                    'disconnect',
                    this.companyId,
                    this.connection.distribution_site_id
                );
            }
        }
    }

    onCancel() {
        if (!this.connection) {
            // revert the toggle to OFF position
            this.isLinked = false;
        }
    }
}
</script>

<style lang="scss" scoped>
.v-input::v-deep .v-label {
    color: $secondary-color;
}
</style>
