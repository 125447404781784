<template>
    <v-container class="px-0" :class="{ disabled: disabled }">
        <p>
            Click the
            <v-icon x-small class="mt-n1">far fa-copy</v-icon>
            icon to copy the link to Company RSS feed.
        </p>
        <v-row dense>
            <v-col
                v-for="(module, key) in feedUrls"
                :key="'rss-feed-' + key"
                class="py-0 d-flex align-center text-subtitle-1"
                md="4"
                cols="12"
            >
                <a :href="module.link" target="_blank" class="hoverable">
                    <v-icon small left>rss-square</v-icon>
                    {{ module.label }}
                </a>
                <v-tooltip
                    bottom
                    :close-delay="copied ? 2000 : 500"
                    :nudge-right="copied ? 20 : 0"
                >
                    <template #activator="{ on, attrs }">
                        <v-btn
                            v-clipboard:copy="module.link"
                            v-clipboard:success="onLinkCopied"
                            icon
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon small>far fa-copy</v-icon>
                        </v-btn>
                    </template>
                    <span>
                        {{ copyTooltip }}
                    </span>
                </v-tooltip>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import type { Company } from '@/types/Company';

const CompanyRssFeedsProps = Vue.extend({
    name: 'CompanyRssFeeds',
    props: {
        company: {
            type: Object as PropType<Company>,
            default() {
                return {
                    id: null
                };
            }
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component
export default class CompanyRssFeeds extends CompanyRssFeedsProps {
    get feedUrls() {
        const baseUrl = 'https://clientcabin.com/distribution_feeds/company';
        return [
            {
                label: 'News Articles',
                link: `${baseUrl}/${this.company.id}/NewsArticles/rss`
            },
            {
                label: 'Blog Posts',
                link: `${baseUrl}/${this.company.id}/BlogPosts/rss`
            },
            {
                label: 'Podcasts',
                link: `${baseUrl}/${this.company.id}/Podcasts/rss`
            },
            {
                label: 'Slideshows',
                link: `${baseUrl}/${this.company.id}/Slideshows/rss`
            },
            {
                label: 'Infographics',
                link: `${baseUrl}/${this.company.id}/Infographics/rss`
            },
            {
                label: 'Videos',
                link: `${baseUrl}/${this.company.id}/VideoPrs/rss`
            }
        ];
    }

    copied = false;

    get copyTooltip() {
        return this.copied ? 'Link Copied' : 'Click to Copy';
    }

    onLinkCopied() {
        this.copied = true;
        setTimeout(this.reset, 3000);
    }

    reset() {
        this.copied = false;
    }
}
</script>

<style lang="scss" scoped>
.disabled {
    opacity: 0.6;
    pointer-events: none;
}

a.hoverable {
    color: $secondary-color;
    transition: all 0.15s ease-out;
    text-decoration: none;
    display: contents;

    &:hover {
        color: $primary-color;

        .v-icon {
            color: $primary-color;
        }
    }
}
</style>
