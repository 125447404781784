import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"pa-0"},[_c(VRow,{class:{ 'grey--text': _vm.disabled },attrs:{"align":"center"}},[_c(VCol,{staticClass:"py-0",attrs:{"cols":"auto"}},[_c(VSwitch,{staticClass:"mt-0 py-3",attrs:{"label":"YouTube","loading":_vm.loading,"disabled":_vm.loading || _vm.disabled,"hide-details":""},on:{"change":_vm.onToggle},model:{value:(_vm.isLinked),callback:function ($$v) {_vm.isLinked=$$v},expression:"isLinked"}})],1),(_vm.connection?.display_name)?_c(VCol,{staticClass:"py-0 overflow-hidden"},[_c(VChip,{attrs:{"small":"","color":"secondary","text-color":"white"}},[_c(VIcon,{attrs:{"left":"","x-small":""}},[_vm._v("user")]),_vm._v(" "+_vm._s(_vm.connection?.display_name)+" ")],1)],1):_vm._e(),_c('connect-youtube',{attrs:{"company-id":_vm.companyId},on:{"cancelled":_vm.onCancel}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }