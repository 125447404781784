<template>
    <modal
        :id="id"
        title="Link LinkedIn Account"
        max-width="600px"
        @close="cancel"
    >
        <template #default>
            <p>We require the permissions to manage your LinkedIn posts.</p>
            <p>
                You can always revoke access to your account by visiting
                <a
                    href="https://www.linkedin.com/help/linkedin/answer/a519947/third-party-applications-data-use?lang=en"
                    target="_blank"
                >
                    https://www.linkedin.com/help/linkedin/answer/a519947/third-party-applications-data-use
                </a>
                , and removing
                <strong>ampifire</strong>
                from the list.
            </p>
        </template>
        <template #actions>
            <v-btn text :block="$vuetify.breakpoint.smAndDown" @click="cancel">
                Cancel
            </v-btn>
            <v-spacer />
            <v-btn
                color="primary"
                :block="$vuetify.breakpoint.smAndDown"
                :loading="isLinking"
                @click="link"
            >
                Link
            </v-btn>
        </template>
    </modal>
</template>

<script lang="ts">
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';

import { Modal } from '@/components/Modal';
import { ConnectApp } from '@/mixins';
import { DistributionSites } from '@/types/Company';

const ConnectLinkedInProps = Vue.extend({
    name: 'ConnectLinkedIn',
    props: {
        companyId: {
            type: Number,
            default() {
                return 0;
            }
        }
    }
});

@Component({
    components: {
        Modal
    }
})
export default class ConnectLinkedIn extends mixins(
    ConnectLinkedInProps,
    ConnectApp
) {
    id = 'connect-linkedin';

    onLink() {
        this.$http
            .get(
                `/connected_apps/oauth_linkedin/${this.companyId}?save_last_location=1`
            )
            .then(({ data }) => {
                this.authorize(data.data.loginUrl, DistributionSites.LinkedIn);
            })
            .catch(() => {
                this.$store.dispatch(
                    'notification/error',
                    'Unable to get LinkedIn authorization URL. Please try again later or contact support.'
                );
            });
    }
}
</script>
