<template>
    <v-card
        class="mb-2"
        :class="{
            'px-5 py-5 mb-5': $vuetify.breakpoint.mdAndUp
        }"
    >
        <v-card-title>
            <v-skeleton-loader loading type="heading" width="75%" />
        </v-card-title>
        <v-card-text>
            <v-skeleton-loader loading type="paragraph" />
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class SectionLoading extends Vue {}
</script>
