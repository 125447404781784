<template>
    <modal
        :id="id"
        title="Connect to YouTube"
        max-width="600px"
        @close="cancel"
    >
        <template #default>
            <p>
                To start distributing your company content to YouTube, we need
                permission to:
            </p>
            <ul class="mt-n2 mb-2">
                <li>Manage your uploaded videos</li>
                <li>Access and view your YouTube account details</li>
            </ul>
            <p>
                Your privacy is important to us. You can review and revoke
                permissions at any time by visiting
                <a
                    href="https://myaccount.google.com/permissions"
                    target="_blank"
                    class="d-inline-block"
                >
                    Google Account Permissions
                </a>
                .
            </p>
        </template>
        <template #actions>
            <v-btn text :block="$vuetify.breakpoint.smAndDown" @click="cancel">
                Cancel
            </v-btn>
            <v-spacer />
            <v-btn
                color="primary"
                :block="$vuetify.breakpoint.smAndDown"
                :loading="isLinking"
                @click="link"
            >
                Continue to YouTube
            </v-btn>
        </template>
    </modal>
</template>

<script lang="ts">
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';

import { Modal } from '@/components/Modal';
import { ConnectApp } from '@/mixins';
import { DistributionSites } from '@/types/Company';

const ConnectYoutubeProps = Vue.extend({
    name: 'ConnectYoutube',
    props: {
        companyId: {
            type: Number,
            default() {
                return 0;
            }
        }
    }
});

@Component({
    components: {
        Modal
    }
})
export default class ConnectYoutube extends mixins(
    ConnectYoutubeProps,
    ConnectApp
) {
    id = 'connect-youtube';

    get account() {
        // youtube audit account
        return this.$store.state.user.id === 29935 ? 'UBC' : 'Vidbullet';
    }

    onLink() {
        this.$http
            .get(
                `/connected_apps/authorize_youtube_api_vidbullet/${this.companyId}?save_last_location=1`
            )
            .then(({ data }) => {
                this.authorize(data.data.loginUrl, DistributionSites.YouTube);
            })
            .catch(() => {
                this.$store.dispatch(
                    'notification/error',
                    'Unable to get YouTube authorization URL. Please try again later or contact support.'
                );
            });
    }
}
</script>
