import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,{staticClass:"mb-2",class:{
        'px-5 py-5 mb-5': _vm.$vuetify.breakpoint.mdAndUp
    }},[_c(VCardTitle,[_c(VSkeletonLoader,{attrs:{"loading":"","type":"heading","width":"75%"}})],1),_c(VCardText,[_c(VSkeletonLoader,{attrs:{"loading":"","type":"paragraph"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }