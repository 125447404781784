<template>
    <v-row class="flex-column">
        <v-col class="pb-0">
            <a-media-uploader
                ref="uploader"
                name="company_logo"
                :disabled="disabled"
                :options="options"
                :crop-aspect-ratio="16 / 9"
                can-rotate-image
                croppable
                upload-cropped
                @uploaded="update"
                @queue="queue"
                @addfile="onFileAdd"
            />
        </v-col>
        <v-col v-if="hasLogo" class="text-center">
            <img :src="logo" class="logo-rounded" alt="Company logo" />
        </v-col>
        <v-col v-else class="ma-auto">
            <v-list-item-avatar
                height="100%"
                width="100%"
                class="rounded-lg pa-2"
                color="main-background"
            >
                <v-icon :size="50" color="grey lighten-2">image</v-icon>
            </v-list-item-avatar>
        </v-col>
    </v-row>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import { AMediaUploader } from '@/components/AForm/Inputs/AMediaUploader';

import type { Company } from '@/types/Company';
import type { MediaFile } from '@/types/MediaFile';

const CompanyLogoProps = Vue.extend({
    name: 'CompanyLogo',
    inheritAttrs: false,
    props: {
        company: {
            type: Object as PropType<Company>,
            default() {
                return {
                    id: 0,
                    user_id: 0,
                    name: '',
                    media_resources: []
                };
            }
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        AMediaUploader
    }
})
export default class CompanyLogo extends CompanyLogoProps {
    $refs!: {
        uploader: InstanceType<typeof AMediaUploader>;
    };

    media: Partial<MediaFile> | null = null;

    cropperOpener: ReturnType<typeof setTimeout> | void = void 0;

    get hasLogo() {
        return Boolean(this.logo);
    }

    get logo() {
        return (
            this.media?.relative_filename ||
            this.company.media_resources[0]?.media_file.relative_filename ||
            void 0
        );
    }

    get options() {
        return {
            media_resource: {
                company_id: this.company.id
            },
            user_id: this.company.user_id,
            title: this.company.name
        };
    }

    isCropped() {
        if (this.$refs.uploader) {
            return (
                this.$refs.uploader.getFiles().length ===
                Object.keys(this.$refs.uploader.cropData).length
            );
        }

        return false;
    }

    queue(handler: () => Promise<void>) {
        this.$emit('queue', () => {
            // upload cropped image only
            if (this.isCropped()) {
                return handler();
            }

            return Promise.resolve(true);
        });
    }

    update({ relative_filename, id }: Partial<MediaFile>) {
        if (id) {
            this.media = {
                id,
                relative_filename
            };
        }
    }

    onFileAdd() {
        if (!this.isCropped()) {
            this.markFileToAttention();
        }
    }

    markFileToAttention() {
        const files = this.$refs.uploader.getFiles();

        files.forEach(file => {
            const isValid = Boolean(file.id in this.$refs.uploader.cropData);

            this.$refs.uploader.markFileValid(file.id, isValid);

            if (!isValid) {
                if (this.cropperOpener) {
                    clearTimeout(this.cropperOpener);
                }

                this.cropperOpener = setTimeout(() => {
                    this.$refs.uploader.tryOpenCropper(file.id);
                }, 500);
            }
        });
    }
}
</script>

<style lang="scss">
.logo-rounded {
    max-height: 200px;
    max-width: 100%;
    width: auto;
    height: 100%;
    border-radius: 10px;
}
</style>
