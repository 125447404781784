import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"px-0",class:{ disabled: _vm.disabled }},[_c('p',[_vm._v(" Click the "),_c(VIcon,{staticClass:"mt-n1",attrs:{"x-small":""}},[_vm._v("far fa-copy")]),_vm._v(" icon to copy the link to Company RSS feed. ")],1),_c(VRow,{attrs:{"dense":""}},_vm._l((_vm.feedUrls),function(module,key){return _c(VCol,{key:'rss-feed-' + key,staticClass:"py-0 d-flex align-center text-subtitle-1",attrs:{"md":"4","cols":"12"}},[_c('a',{staticClass:"hoverable",attrs:{"href":module.link,"target":"_blank"}},[_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v("rss-square")]),_vm._v(" "+_vm._s(module.label)+" ")],1),_c(VTooltip,{attrs:{"bottom":"","close-delay":_vm.copied ? 2000 : 500,"nudge-right":_vm.copied ? 20 : 0},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(module.link),expression:"module.link",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onLinkCopied),expression:"onLinkCopied",arg:"success"}],attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"small":""}},[_vm._v("far fa-copy")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.copyTooltip)+" ")])])],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }