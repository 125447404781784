<template>
    <v-container :key="lastRefresh" ref="wrapper" class="pa-0">
        <p :class="{ 'grey--text': disabled }">
            Connect your accounts, and we'll automatically distribute your Amps
            to the selected platforms.
        </p>
        <p v-if="!isCompanyOwner" class="text-caption primary--text">
            <v-icon color="warning" left small>exclamation-circle</v-icon>
            Be careful with linking proper accounts to this Company
        </p>
        <youtube-connector
            :company-id="company.id"
            :connection="getConnection(DistributionSites.YouTube)"
            :loading="isBusy"
            :disabled="disabled"
            @disconnect="disconnect"
        />
        <vimeo-connector
            :company-id="company.id"
            :connection="getConnection(DistributionSites.Vimeo)"
            :loading="isBusy"
            :disabled="disabled"
            @disconnect="disconnect"
        />
        <slide-share-connector
            :company-id="company.id"
            :connection="getConnection(DistributionSites.SlideShare)"
            :loading="isBusy"
            :disabled="disabled"
            @disconnect="disconnect"
        />
        <tik-tok-connector
            v-if="hasAiHidden"
            :company-id="company.id"
            :connection="getConnection(DistributionSites.TikTok)"
            :loading="isBusy"
            :disabled="disabled"
            @disconnect="disconnect"
        />
        <twitter-connector
            v-if="hasAiFeatures"
            :company-id="company.id"
            :connection="getConnection(DistributionSites.Twitter)"
            :loading="isBusy"
            :disabled="disabled"
            @disconnect="disconnect"
        />
        <linked-in-connector
            v-if="hasAiFeatures"
            :company-id="company.id"
            :connection="getConnection(DistributionSites.LinkedIn)"
            :loading="isBusy"
            :disabled="disabled"
            @disconnect="disconnect"
        />
    </v-container>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import { YoutubeConnector } from './Youtube';
import { VimeoConnector } from './Vimeo';
import { SlideShareConnector } from './SlideShare';
import { TikTokConnector } from './TikTok';
import { TwitterConnector } from './Twitter';
import { LinkedInConnector } from './LinkedIn';

import {
    Company,
    CompanyAccountConnection,
    AppConnectionCookie,
    DistributionSites,
    CompanyAccountConnectionCookie
} from '@/types/Company';

import { ProvideReactive } from '@/utils/decorators';
import { mapGetters, mapState } from 'vuex';
import { RootState } from '@/store/State';

const ConnectedAppsProps = Vue.extend({
    name: 'ConnectedApps',
    props: {
        company: {
            type: Object as PropType<Company>,
            default() {
                return {
                    id: 0,
                    user_id: 0,
                    user: {
                        has_ai_features: false,
                        reseller_id: 0
                    },
                    company_oauths: []
                };
            }
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        YoutubeConnector,
        VimeoConnector,
        SlideShareConnector,
        TikTokConnector,
        TwitterConnector,
        LinkedInConnector
    },
    computed: {
        ...mapState({
            userId: (state): number | void => (state as RootState).user?.id
        }),
        ...mapGetters('user', ['isAdmin', 'isEditor', 'isReseller'])
    }
})
export default class ConnectedApps extends ConnectedAppsProps {
    userId!: number;
    isAdmin!: boolean;
    isEditor!: boolean;
    isReseller!: boolean;

    $refs!: {
        wrapper: HTMLDivElement;
    };

    isRefreshing = false;
    lastRefresh = +new Date();

    isDisconnecting = false;

    localConnections: CompanyAccountConnection[] | null = null;

    get actualConnections() {
        return this.localConnections || this.company.company_oauths;
    }

    get isCompanyOwner() {
        if (this.isAdmin || this.isEditor) {
            return true;
        }

        return (
            this.company.user_id === this.userId ||
            (this.isReseller && this.company.user?.reseller_id === this.userId)
        );
    }

    get hasAiHidden() {
        return this.$route.query.ai || false;
    }

    get hasAiFeatures() {
        return Boolean(this.company.user?.has_ai_features);
    }

    get isBusy() {
        return this.isDisconnecting || this.isRefreshing;
    }

    // allow enum usage in template above
    DistributionSites = DistributionSites;

    @ProvideReactive()
    refreshConnections() {
        this.isRefreshing = true;

        this.$http
            .get(`/companies/view/${this.company.id}`, { cache: false })
            .then(({ data }) => {
                if (data?.data) {
                    this.onData(data.data);
                }
            })
            .finally(() => {
                this.lastRefresh = +new Date();
                this.isRefreshing = false;
            });
    }

    mounted() {
        if (this.company?.id) {
            this.verifyAppConnection();
        }
    }

    onData(data: { company: Company }) {
        if (data.company) {
            this.localConnections = data.company.company_oauths;

            this.verifyAppConnection();
        }
    }

    getConnection(site: DistributionSites) {
        return this.actualConnections.find(
            connection => connection.distribution_site_id === site
        );
    }

    disconnect(companyId: number, siteId: number) {
        this.isDisconnecting = true;

        this.$http
            .post(
                `/company_oauths/delete.json`,
                {
                    company_id: companyId,
                    distribution_site_id: siteId
                },
                { cache: false }
            )
            .finally(() => {
                this.isDisconnecting = false;

                this.refreshConnections();
            });
    }

    async verifyAppConnection() {
        const pendingConnection: CompanyAccountConnectionCookie =
            this.$cookies.get(AppConnectionCookie);

        if (pendingConnection) {
            await this.focus();

            if (this.getConnection(pendingConnection.site)) {
                this.$store.dispatch(
                    'notification/success',
                    `${DistributionSites[pendingConnection.site]} account connected successfully`
                );
            } else {
                this.$store.dispatch(
                    'notification/error',
                    `Unable to authorize ${DistributionSites[pendingConnection.site]} account. Please try again later or contact support.`
                );
            }

            this.$cookies.remove(AppConnectionCookie);
        }
    }

    async focus() {
        return this.$vuetify.goTo(this.$refs.wrapper);
    }
}
</script>
