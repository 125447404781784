<template>
    <modal :id="id" title="Address Verification">
        <template #default>
            <p>We were unable to verify the following address:</p>
            <p>{{ address }}</p>
            <p>
                <strong>Press CONTINUE to use this address</strong>
                <br />
                <strong>Press CANCEL to correct manually and re-verify</strong>
            </p>
        </template>
        <template #actions>
            <v-btn text :block="$vuetify.breakpoint.smAndDown" @click="cancel">
                Cancel
            </v-btn>
            <v-spacer />
            <v-btn
                color="primary"
                :block="$vuetify.breakpoint.smAndDown"
                @click="confirm"
            >
                Continue
            </v-btn>
        </template>
    </modal>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Modal } from '@/components/Modal';

const NonVerifiedAddressProps = Vue.extend({
    name: 'NonVerifiedAddress',
    props: {
        id: {
            type: String,
            required: true
        },
        address: {
            type: String,
            default() {
                return '';
            }
        }
    }
});

@Component({
    components: {
        Modal
    }
})
export default class NonVerifiedAddress extends NonVerifiedAddressProps {
    confirm() {
        this.$emit('confirm', true);
    }

    cancel() {
        this.$emit('confirm', false);
    }
}
</script>
