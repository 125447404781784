<template>
    <v-row class="flex-column">
        <v-col class="pb-0">
            <a-media-uploader
                accepted-file-types="application/pdf"
                name="business_certificate"
                :disabled="disabled"
                :options="options"
                :allow-process="false"
                :label="label"
                @uploaded="update"
                @queue="queue"
            />
        </v-col>
        <v-col v-if="hasCertificate">
            <a
                :href="certificate"
                target="_blank"
                class="link-container font-weight-medium justify-center align-center d-flex pa-4"
            >
                <text-truncated :text="certificateTitle" />
            </a>
        </v-col>
        <v-col v-else class="ma-auto">
            <v-list-item-avatar
                height="100%"
                width="100%"
                class="rounded-lg pa-2"
                color="main-background"
            >
                <v-icon :size="50" color="grey lighten-2">file-contract</v-icon>
            </v-list-item-avatar>
        </v-col>
    </v-row>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import { TextTruncated } from '@/components/TextTruncated';
import { AMediaUploader } from '@/components/AForm/Inputs/AMediaUploader';

import type { Company } from '@/types/Company';
import type { MediaFile } from '@/types/MediaFile';

const BusinessCertificateProps = Vue.extend({
    name: 'BusinessCertificate',
    inheritAttrs: false,
    props: {
        company: {
            type: Object as PropType<Company>,
            default() {
                return {
                    id: 0,
                    user_id: 0,
                    name: '',
                    interwire_business_registration_certificate: null
                };
            }
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        TextTruncated,
        AMediaUploader
    }
})
export default class BusinessCertificate extends BusinessCertificateProps {
    media: Partial<MediaFile> | null = null;

    get label() {
        return `
                Drop file here / Click to select
                <br />
                <small>Only PDF files accepted. Maximum 5 MB.</small>
            `;
    }

    get hasCertificate() {
        return Boolean(this.certificate);
    }

    get certificate() {
        return (
            this.media?.relative_filename ||
            this.company.interwire_business_registration_certificate?.media_file
                .relative_filename ||
            void 0
        );
    }

    get certificateTitle() {
        return (
            this.media?.title ||
            this.company.interwire_business_registration_certificate?.media_file
                .title
        );
    }

    get options() {
        return {
            media_resource: {
                interwire_business_registration_certificate_id: this.company.id
            },
            title: this.company.name
        };
    }

    queue(handler: unknown) {
        this.$emit('queue', handler);
    }

    update({ relative_filename, id, title }: Partial<MediaFile>) {
        if (id) {
            this.media = {
                id,
                title,
                relative_filename
            };
        }
    }
}
</script>

<style lang="scss">
$color: #fbfbfe;

.link-container {
    background: $color;
    border-radius: 0.5em;
    border: 1px dashed #d8d8d8;
    height: 100%;
    transition: background-color 0.3s ease;

    &:hover {
        background: darken($color, 2%);
    }
}
</style>
