<template>
    <modal :id="id" title="Address Verification">
        <template #default>
            <p>We found the following verified address:</p>
            <p>{{ suggestedAddress }}</p>
            <p>
                Press
                <strong>ACCEPT</strong>
                to continue using your current address or
                <strong>USE RECOMMENDED</strong>
                to use the address found.
            </p>
        </template>
        <template #actions>
            <v-btn text :block="$vuetify.breakpoint.smAndDown" @click="accept">
                Accept
            </v-btn>
            <v-spacer />
            <v-btn
                color="primary"
                :block="$vuetify.breakpoint.smAndDown"
                @click="use"
            >
                Use Recommended
            </v-btn>
        </template>
    </modal>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { Modal } from '@/components/Modal';
import type { AddressVerificationResult } from '.';

const SuggestedAddressProps = Vue.extend({
    name: 'SuggestedAddress',
    props: {
        id: {
            type: String,
            required: true
        },
        address: {
            type: Object as PropType<AddressVerificationResult>,
            default() {
                return {
                    suggested: null
                };
            }
        }
    }
});

@Component({
    components: {
        Modal
    }
})
export default class SuggestedAddress extends SuggestedAddressProps {
    get suggestedAddress() {
        return this.address?.suggested?.formatted_address;
    }

    use() {
        this.$emit('confirm', true);
    }

    accept() {
        this.$emit('confirm', false);
    }
}
</script>
