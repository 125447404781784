<template>
    <modal :id="id" title="Connect to Vimeo" max-width="600px" @close="cancel">
        <template #default>
            <p>
                To distribute your company content on Vimeo, we need permission
                to:
            </p>
            <ul class="mt-n2 mb-2">
                <li>Upload and manage your videos</li>
                <li>Access and view your Vimeo account details</li>
            </ul>
            <p>
                Your privacy is important to us. You can review and revoke these
                permissions at any time by visiting your
                <a
                    href="https://vimeo.com/settings"
                    target="_blank"
                    class="d-inline-block"
                >
                    Vimeo Account Settings
                </a>
                .
            </p>
        </template>
        <template #actions>
            <v-btn text :block="$vuetify.breakpoint.smAndDown" @click="cancel">
                Cancel
            </v-btn>
            <v-spacer />
            <v-btn
                color="primary"
                :block="$vuetify.breakpoint.smAndDown"
                :loading="isLinking"
                @click="link"
            >
                Continue to Vimeo
            </v-btn>
        </template>
    </modal>
</template>

<script lang="ts">
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';

import { Modal } from '@/components/Modal';
import { ConnectApp } from '@/mixins';
import { DistributionSites } from '@/types/Company';

const ConnectVimeoProps = Vue.extend({
    name: 'ConnectVimeo',
    props: {
        companyId: {
            type: Number,
            default() {
                return 0;
            }
        }
    }
});

@Component({
    components: {
        Modal
    }
})
export default class ConnectVimeo extends mixins(
    ConnectVimeoProps,
    ConnectApp
) {
    id = 'connect-vimeo';

    onLink() {
        this.$http
            .get(
                `/connected_apps/authorize_vimeo_api/${this.companyId}?save_last_location=1`
            )
            .then(({ data }) => {
                this.authorize(data.data.loginUrl, DistributionSites.Vimeo);
            })
            .catch(() => {
                this.$store.dispatch(
                    'notification/error',
                    'Unable to get Vimeo authorization URL. Please try again later or contact support.'
                );
            });
    }
}
</script>
