<template>
    <modal
        :id="id"
        title="Connect to SlideShare"
        max-width="600px"
        @close="cancel"
    >
        <template #default>
            <a-form ref="form" :submit="link" :disabled="isLinking">
                <p>
                    Please enter your SlideShare credentials to connect your
                    account.
                </p>
                <a-text-input
                    v-model="username"
                    label="Username"
                    rules="required"
                    placeholder="Enter your SlideShare email or username"
                />
                <a-text-input
                    v-model="password"
                    label="Password"
                    placeholder="Enter your SlideShare password"
                    vid="password"
                    rules="required"
                    :type="passwordInputType"
                    :append-icon="passwordInputIcon"
                    @click:append="toggle"
                />
                <p>
                    By linking your account, you authorize us to upload and
                    manage your presentations on SlideShare. You can revoke
                    access at any time via your SlideShare Account Settings.
                </p>
                <v-container class="pa-0">
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-btn
                                text
                                :block="$vuetify.breakpoint.smAndDown"
                                @click="cancel"
                            >
                                Cancel
                            </v-btn>
                        </v-col>
                        <v-col cols="12" md="6" class="text-right">
                            <v-btn
                                type="submit"
                                color="primary"
                                :block="$vuetify.breakpoint.smAndDown"
                                :loading="isLinking"
                            >
                                Connect Account
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </a-form>
        </template>
    </modal>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { AForm } from '@/components/AForm';
import { Modal } from '@/components/Modal';
import { ATextInput } from '@/components/AForm/Inputs/ATextInput';

const ConnectSlideshareProps = Vue.extend({
    name: 'ConnectSlideshare',
    props: {
        companyId: {
            type: Number,
            default() {
                return 0;
            }
        }
    }
});

@Component({
    components: {
        Modal,
        AForm,
        ATextInput
    }
})
export default class ConnectSlideshare extends ConnectSlideshareProps {
    $refs!: {
        form: InstanceType<typeof AForm>;
    };

    id = 'connect-slideshare';

    isLinking = false;

    controller: AbortController | null = null;

    username = '';
    password = '';

    isPasswordVisible = false;

    link() {
        this.isLinking = true;

        this.controller = new AbortController();

        this.$http
            .post(
                '/connected_apps/credentials_slideshare.json',
                {
                    company_id: this.companyId,
                    username: this.username,
                    password: this.password
                },
                {
                    signal: this.controller.signal
                }
            )
            .then(({ data }) => {
                if (!data.data.success) {
                    this.$store.dispatch(
                        'notification/error',
                        data.data.errors
                    );

                    return;
                }

                this.$store.dispatch(
                    'notification/success',
                    'Slideshare account connected successfully'
                );

                this.$emit('connected');

                this.close();
            })
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch(
                        'notification/error',
                        'Unable to authorize Slideshare account. Please try again later or contact support.'
                    );
                }
            })
            .finally(() => {
                this.isLinking = false;
            });
    }

    cancel() {
        this.close();

        this.$emit('cancelled');
    }

    close() {
        this.isLinking = false;

        this.reset();

        this.$store.dispatch('modal/close', this.id);
    }

    get passwordInputType() {
        return this.isPasswordVisible ? 'text' : 'password';
    }

    get passwordInputIcon() {
        return this.isPasswordVisible ? 'eye-slash' : 'eye';
    }

    toggle() {
        this.isPasswordVisible = !this.isPasswordVisible;
    }

    reset() {
        if (this.controller) {
            this.controller.abort();
            this.controller = null;
        }

        this.username = '';
        this.password = '';

        this.$refs.form.reset(false);
    }
}
</script>
